// extracted by mini-css-extract-plugin
export var wrapper = "Hero-module--wrapper--aiQ2z";
export var light = "Hero-module--light--FfANi";
export var dark = "Hero-module--dark--2PmF2";
export var burger = "Hero-module--burger--2PBWN";
export var large = "Hero-module--large--AOZW3";
export var navigation = "Hero-module--navigation--3J1Vp";
export var logo = "Hero-module--logo--1ETsk";
export var rightSide = "Hero-module--rightSide--2jp31";
export var text = "Hero-module--text--2Q4pQ";
export var social = "Hero-module--social--2DTKM";
export var telephoneNumber = "Hero-module--telephoneNumber--1UrGd";
export var pageIntro = "Hero-module--pageIntro--2nsZ7";
export var background = "Hero-module--background--2XWHQ";
export var scrollArrow = "Hero-module--scrollArrow--2WtYm";
export var bounce = "Hero-module--bounce--3Gi70";