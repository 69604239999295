import React, { FC, useContext } from "react"
import * as css from "./Modal.module.scss"
import { GlobalContext } from "~/GlobalProvider"

export enum ModalSize {
  Small = "small",
  Medium = "medium",
}

const Modal: FC = () => {
  const {
    modalOpen,
    setModalOpen,
    modalContents,
    setModalContents,
    modalName,
    setModalName,
    modalSize,
    setModalSize,
  } = useContext(GlobalContext)

  const closeModal = () => {
    setModalOpen(false)
    setModalContents(null)
    setModalName(undefined)
    setModalSize(undefined)
  }

  return (
    <div
      className={`${css.wrapper} ${css[modalSize]} ${
        css[modalOpen ? "open" : ""]
      }`}
    >
      <div
        className="backdrop"
        onClick={() => {
          closeModal()
        }}
      />
      <div className={css.innerWrapper}>
        <div className={css.info}>
          {modalName && <h4>{modalName}</h4>}
          <button
            onClick={() => {
              closeModal()
            }}
          >
            <h5>Close &times;</h5>
          </button>
        </div>
        {modalContents}
      </div>
    </div>
  )
}

export default Modal
