import React, { FC } from "react"
import * as css from "./Loader.module.scss"

const Loader: FC = () => {
  return (
    <div className={css.wrapper}>
      <svg
        width="81"
        height="81"
        viewBox="0 0 81 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.0015 10.0012C47.958 10.0012 55.5886 13.1619 61.2147 18.788C66.8408 24.4141 70.0015 32.0447 70.0015 40.0012H63.3348C63.3348 33.8128 60.8765 27.8779 56.5006 23.5021C52.1248 19.1262 46.1898 16.6679 40.0015 16.6679V10.0012Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

export default Loader
