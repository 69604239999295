const scrollTo = (className: string, offset = 0) => {
  const element = document.getElementsByClassName(className)[0]

  if (element) {
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.pageYOffset + offset,
      behavior: "smooth",
    })
  }
}

export default scrollTo
