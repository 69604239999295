import React, { FC, useEffect, useState } from "react"
import * as css from "./Video.module.scss"
import Player from "@vimeo/player"

type VideoProps = {
  className: string
  id: string
  title?: string
  provider: string
  url: string
  thumbnailUrl: string
}

const Video: FC<VideoProps> = ({
  className,
  id,
  title,
  provider,
  url,
  thumbnailUrl,
}) => {
  const [thumbnailImage, setThumbnailImage] = useState<string>()
  const [videoId, setVideoId] = useState<string>()
  const [showVideo, setShowVideo] = useState<boolean>(false)

  useEffect(() => {
    if (videoId && provider === "vimeo") {
      const options = {
        id: videoId,
        width: 1920,
        loop: true,
        autoplay: true,
        muted: true,
        controls: false,
        title: false,
      }

      const player = new Player(id, options)

      player.on("play", () => {
        setShowVideo(true)
      })
    }
  }, [videoId])

  useEffect(() => {
    const thumbnailUrlPieces = thumbnailUrl.split("_")
    thumbnailUrlPieces.pop()
    thumbnailUrlPieces.push("1920")

    setThumbnailImage(thumbnailUrlPieces.join("_"))
  }, [])

  useEffect(() => {
    setVideoId(url.split("?")[0].split("/").pop())
  }, [])

  return (
    <>
      {thumbnailImage && (
        <div
          className={css.thumbnail}
          style={{ backgroundImage: `url(${thumbnailImage})` }}
        />
      )}
      <div
        className={[css.video, className, showVideo && css.showVideo].join(" ")}
        id={id}
      />
    </>
  )
}

export default Video
