import GatsbyLink from "gatsby-link"
import React, { FC, useEffect, useState } from "react"
import * as css from "./Link.module.scss"

type LinkProps = {
  button?: boolean
  to: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  leftArrow?: boolean
  rightArrow?: boolean
}

const Link: FC<LinkProps> = ({ button, to, onClick, className, leftArrow, rightArrow, children: linkChildren }) => {
  const [linkClassName, setLinkClassName] = useState(className)
  const [children, setChildren] = useState(linkChildren)

  useEffect(() => {
    setLinkClassName(`${button ? css.button : ""} ${leftArrow || rightArrow ? css.withIcon : ''} ${className}`)

    if (leftArrow) {
      setChildren(
        <>
          <svg
            width="45"
            height="44"
            viewBox="0 0 45 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{transform: 'scaleX(-1)'}}
          >
            <path
              d="M31.4266 10.4308L29.4821 12.3753L37.7318 20.625H1.875V23.3751H37.7316L29.4821 31.6246L31.4266 33.5691L42.9959 22L31.4266 10.4308Z"
              fill="#303E4B"
            />
          </svg>
          {children}
        </>
      )
    }

    if (rightArrow) {
      setChildren(<>
        {children}
        <svg
          width="45"
          height="44"
          viewBox="0 0 45 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.4266 10.4308L29.4821 12.3753L37.7318 20.625H1.875V23.3751H37.7316L29.4821 31.6246L31.4266 33.5691L42.9959 22L31.4266 10.4308Z"
            fill="#303E4B"
          />
        </svg>
      </>)
    }
  }, [])

  if (!to && onClick) {
    return (
      <button onClick={onClick} className={linkClassName}>
        {children}
      </button>
    )
  }

  if (to[0] === "/") {
    return (
      <GatsbyLink to={to} className={linkClassName}>
        {children}
      </GatsbyLink>
    )
  }

  if (!to.split(":")[0]) {
    return null
  }

  return (
    <a
      className={linkClassName}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default Link
