import React, { FC, useContext, useState } from "react"
import Slider from "react-slick"
import Image from "gatsby-image"
import { GlobalContext } from "~/GlobalProvider"
import Link from "~/components/atoms/Link/Link"
import { StructuredText } from "react-datocms"

import * as css from "./ApartmentsList.module.scss"
import { ModalSize } from "~/components/atoms/Modal/Modal"
import Loader from "~/components/atoms/Loader/Loader"
import WithBackground from "../../../WithBackground/WithBackground"
import slugify from "slugify"

export const Apartment: FC<any> = ({
  bedrooms,
  description,
  amenities,
  images,
  name: apartmentName,
  shortDescription,
  propertyId,
  expanded,
}) => {
  const { setModalOpen, setModalContents, setModalName, setModalSize } =
    useContext(GlobalContext)
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false)

  const openGallery = (galleryImages: Array<any>) => {
    const settings = {
      infinite: true,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    setModalContents(
      <Slider {...settings}>
        {galleryImages.map(({ fluid, title }, index) => (
          <Image fluid={fluid} key={index} title={title} />
        ))}
      </Slider>
    )

    setModalName("Gallery")

    setModalOpen(true)
  }

  const openBookingCalendar = (id?: string) => {
    setModalContents(
      <>
        <Loader />
        <div
          data-calendar-key="E5625D5187872EC11D5BEDFB0D7AFB04577740D8187E8281EB0724635BFCD274BB21E3310455FB19ADA734DB3D412A03DB9F80E95298CDD8"
          data-calendar-property-id={id}
          style={{ lineHeight: 0, position: "relative" }}
        />
      </>
    )

    setModalName("")

    setModalSize(ModalSize.Small)

    setModalOpen(true)
  }

  return (
    <div className={`${css.apartment} ${expanded ? css.expanded : ''}`}>
      {!!images.length && (
        <div
          className={css.image}
          onClick={() => {
            openGallery(images)
          }}
        >
          <Image fluid={images[0]?.fluid} />
          <div className={css.openGallery}>
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="27"
                cy="27"
                r="26"
                fill="white"
                stroke="#537495"
                stroke-width="2"
              />
              <path
                d="M24.5 38.25V35.75H20.0125L25.6375 30.125L23.875 28.3625L18.25 33.9875V29.5H15.75V38.25H24.5ZM30.125 25.6375L35.75 20.0125V24.5H38.25V15.75H29.5V18.25H33.9875L28.3625 23.875L30.125 25.6375Z"
                fill="#537495"
              />
            </svg>
          </div>
        </div>
      )}
      <div className={css.details}>
        <div className={css.top}>
          {!expanded && <Link to={`/${slugify(apartmentName.toLowerCase())}`}><h3>{apartmentName}</h3></Link>}
          <div className={css.amenities}>
            <div
              className={css.bedrooms}
              title={`${bedrooms} bedroom${bedrooms !== 1 ? "s" : ""}`}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 25.5V21.75C32 20.375 30.875 19.25 29.5 19.25H14.5C13.125 19.25 12 20.375 12 21.75V25.5C10.625 25.5 9.5 26.625 9.5 28V34.25H11.1625L11.8 36.1625C11.925 36.5125 12.25 36.75 12.625 36.75C12.8065 36.7497 12.9833 36.693 13.1311 36.5877C13.279 36.4825 13.3904 36.3339 13.45 36.1625L14.0875 34.25H29.925L30.5625 36.1625C30.675 36.5125 31 36.75 31.375 36.75C31.5565 36.7497 31.7333 36.693 31.8811 36.5877C32.0289 36.4825 32.1404 36.3339 32.2 36.1625L32.8375 34.25H34.5V28C34.5 26.625 33.375 25.5 32 25.5ZM20.75 25.5H14.5V23C14.5 22.3125 15.0625 21.75 15.75 21.75H20.75V25.5ZM29.5 25.5H23.25V21.75H28.25C28.9375 21.75 29.5 22.3125 29.5 23V25.5Z"
                  fill="#537495"
                />
                <circle
                  cx="24"
                  cy="24"
                  r="23"
                  stroke="#537495"
                  stroke-width="2"
                />
              </svg>

              <span>{bedrooms}</span>
            </div>
            {amenities
              .filter(
                ({ name }) =>
                  apartmentName !== "Apartment 28" || name !== "Dog friendly"
              )
              .map(({ name, icon }, index) => (
                <img src={icon.url} title={name} key={index} />
              ))}
          </div>
          {expanded && <Link
            button
            onClick={() => {
              openBookingCalendar(propertyId)
            }}
            className={css.bookButton}
          >
            Book now
          </Link>
          }
        </div>
        <div className={css.description}>
          <StructuredText data={shortDescription.value} />
          {(descriptionOpen || expanded) && <StructuredText data={description.value} />}
        </div>
        <div className={css.actions}>
          {!expanded && !descriptionOpen && (
            <Link
              button
              onClick={() => {
                setDescriptionOpen(true)
              }}
              className={css.copyToggle}
            >
              More info
            </Link>
          )}
          {!expanded && descriptionOpen && (
            <Link
              button
              onClick={() => {
                setDescriptionOpen(false)
              }}
              className={css.copyToggle}
            >
              Less info
            </Link>
          )}
          <Link
            button
            onClick={() => {
              openBookingCalendar(propertyId)
            }}
            className={css.bookButton}
          >
            Book now
          </Link>
        </div>
      </div>
    </div>
  )
}

type ApartmentsListProps = {
  apartments: Array<{
    bedrooms: number
    description: {
      value: any
    }
    amenities: Array<{
      icon: {
        url: string
      }
      name: string
    }>
    images: Array<{
      fluid: any
      title: string
    }>
    name: string
    shortDescription: {
      value: any
    }
  }>
}

const ApartmentsList: FC<ApartmentsListProps> = ({ apartments }) => (
  <WithBackground>
    <div className={css.wrapper}>
      {apartments.map((apartment, index) => (
        <Apartment {...apartment} key={index} />
      ))}
    </div>
  </WithBackground>
)

export default ApartmentsList
