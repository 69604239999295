import React, { FC, useContext, useEffect, useState } from "react"
import { GlobalContext } from "~/GlobalProvider"
import Link from "~/components/atoms/Link/Link"
import * as css from "./Footer.module.scss"
import GatsbyImage from "gatsby-image"

const Footer: FC = () => {
  const {
    companyInfo: {
      companyName,
      address,
      telephoneNumber,
      email,
      logoLargeColour,
      ownersLogin,
      fbUrl,
    },
  } = useContext(GlobalContext)

  const [year, setYear] = useState(2021)

  useEffect(() => {
    const y = new Date().getFullYear()

    setYear(y)
  }, [])

  return (
    <footer className={css.wrapper}>
      <div className={css.details}>
        <p>
          <strong>{companyName}</strong>
          <br />
          {address}
        </p>
        <p>
          <Link to={`mailto:${email}`}>{email}</Link>
        </p>
        {fbUrl && (
          <Link
            className={`${css.social} ${css.fb}`}
            to={fbUrl}
          >
            <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M140.6 27.6C128.1 30.6 117.1 39 111.7 49.6C107 58.9 105.9 64.6 105.3 82.7L104.7 99.9L90.1 100.2L75.5 100.5V120.5V140.5L90.3 140.8L105 141.1V183C105 207.8 105.4 225.9 106 227.4L107 230H126.9C145.7 230 147 229.9 148 228.1C148.6 226.8 149 211 149 183.6V141H165.3C176.6 141 182 140.6 182.8 139.8C183.6 139 184 133.2 184 120.4C184 105.1 183.7 101.9 182.4 100.6C181.1 99.3 178.1 99 164.9 99H149V85.1C149 73.2 149.3 70.9 150.8 69C153.1 66.2 159 65.1 172.6 65.1C179 65 183 64.6 183.8 63.8C185.5 62.1 185.5 28.9 183.8 27.2C182 25.4 148.3 25.7 140.6 27.6Z" fill="#3B5998"/>
            </svg>
          </Link>
        )}
      </div>
      <GatsbyImage
        className={css.image}
        fixed={{ ...logoLargeColour.fixed, width: 400, height: 177 }}
      />
      <div className={css.otherStuff}>
        <div className={css.actions}>
          <Link
            className={css.telephoneNumber}
            to={`tel:${telephoneNumber.replace(/ /g, "")}`}
          >
            {telephoneNumber}
          </Link>
          <Link button className={css.ownersLogin} to={ownersLogin}>
            Owners login
          </Link>
        </div>
        <div className={css.suffix}>
          &copy; {companyName} {year}
          <br />
          <span className={css.spacer} />
          Site by <Link to="https://olcdigital.com">OLC Digital</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
