// extracted by mini-css-extract-plugin
export var bedrooms = "ApartmentsList-module--bedrooms--13qAI";
export var wrapper = "ApartmentsList-module--wrapper--iXl5h";
export var apartment = "ApartmentsList-module--apartment--3mPtU";
export var expanded = "ApartmentsList-module--expanded--3hPvo";
export var image = "ApartmentsList-module--image--3hs_T";
export var openGallery = "ApartmentsList-module--openGallery--1lMiL";
export var details = "ApartmentsList-module--details--u8RhH";
export var top = "ApartmentsList-module--top--48n6s";
export var amenities = "ApartmentsList-module--amenities--25Sy_";
export var bookButton = "ApartmentsList-module--bookButton--38IUH";
export var actions = "ApartmentsList-module--actions--2EFbb";
export var copyToggle = "ApartmentsList-module--copyToggle--EOT8d";
export var description = "ApartmentsList-module--description--3NfZF";