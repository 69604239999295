import { graphql, useStaticQuery } from "gatsby"
import React, { Dispatch, SetStateAction, useState } from "react"
import { ModalSize } from "./components/atoms/Modal/Modal"

export const GlobalContext = React.createContext<{
  navigation?: {
    name: string
    pages?: Array<{
      name: string
      slug: string
    }>
  }
  companyInfo?: any
  menuOpen?: boolean
  setMenuOpen: Dispatch<SetStateAction<boolean>>
  modalName?: string
  setModalName: Dispatch<SetStateAction<string>>
  modalOpen?: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  modalContents?: JSX.Element
  setModalContents: Dispatch<SetStateAction<JSX.Element>>
  modalSize?: ModalSize
  setModalSize: Dispatch<SetStateAction<ModalSize>>
  pageContext?: any
  setPageContext: Dispatch<SetStateAction<any>>
}>({})

export const GlobalProvider = ({ children }: { children: any }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [modalName, setModalName] = useState<string>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContents, setModalContents] = useState<JSX.Element>()
  const [modalSize, setModalSize] = useState<ModalSize>()
  const [pageContext, setPageContext] = useState<any>()

  const {
    datoCmsCompanyInformation: companyInfo,
    datoCmsNavigation: navigation,
  }: Record<string, any> = useStaticQuery(graphql`
    query MyGlobalQuery {
      datoCmsNavigation {
        name
        pages {
          name
          slug
        }
      }
      datoCmsCompanyInformation {
        telephoneNumber
        logoSmallWhite {
          fixed(height: 176) {
            aspectRatio
            src
            width
            srcSet
            height
          }
        }
        logoSmallColour {
          fixed(height: 176) {
            aspectRatio
            src
            width
            srcSet
            height
          }
        }
        logoLargeWhite {
          fixed(width: 800) {
            aspectRatio
            src
            width
            srcSet
            height
          }
        }
        logoLargeColour {
          fixed(width: 800) {
            aspectRatio
            src
            width
            srcSet
            height
          }
        }
        bookingSystem
        companyName
        email
        address
        ownersLogin
        fbUrl
      }
    }
  `)

  return (
    <GlobalContext.Provider
      value={{
        navigation,
        companyInfo,
        menuOpen,
        setMenuOpen,
        modalName,
        setModalName,
        modalOpen,
        setModalOpen,
        modalContents,
        setModalContents,
        modalSize,
        setModalSize,
        pageContext,
        setPageContext
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
