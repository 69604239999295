import Image from "gatsby-image"
import React, { FC, useContext, useEffect, useState } from "react"
import Vimeo from "@vimeo/player"
import { GlobalContext } from "~/GlobalProvider"
import Link from "~/components/atoms/Link/Link"
import scrollTo from "~/utils/scrollTo"
import { xlp } from "~/styles/modules/typography.module.scss"
import { hiddenInMobile } from "~/styles/modules/layout.module.scss"
import * as css from "./Hero.module.scss"
import Video from "~/components/atoms/Video/Video"

type HeroProps = {
  heading: string
  subheading?: string
  video?: string
  image?: any
  showLargeLogo?: boolean
}

const Hero: FC<HeroProps> = ({ heading, subheading, video, image, showLargeLogo }) => {
  const [colour, setColour] = useState<"light" | "dark">(undefined)
  const [large, setLarge] = useState<Boolean>(false)
  const { companyInfo, menuOpen, setMenuOpen } = useContext(GlobalContext)

  useEffect(() => {
    setLarge(!!(video || image))
    setColour(video || image ? "light" : "dark")
  }, [companyInfo])

  return (
    <div
      className={`${css.wrapper} ${large ? css.large : ""} ${
        css[video || image ? "light" : "dark"]
      }`}
    >
      {video && (
        <>
          <div className={css.background}>
            <Video id="hero-video" className={css.video} {...video} />
          </div>
        </>
      )}
      {!video && image && (
        <div className={css.background}>
          <Image fluid={image.fluid} />
        </div>
      )}
      <div className={css.navigation}>
          <button
            className={css.burger}
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}
          >
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.5 33H5.5V29.3333H38.5V33ZM38.5 23.8333H5.5V20.1667H38.5V23.8333ZM38.5 14.6667H5.5V11H38.5V14.6667Z"
                fill="white"
              />
            </svg>
          </button>
        {((!video && !image) || !showLargeLogo) && (
          <Link to="/" className={css.logo}>
            <Image
              fixed={
                (colour === "light"
                  ? companyInfo.logoSmallWhite
                  : companyInfo.logoSmallColour
                ).fixed
              }
            />
          </Link>
        )}
        <div className={css.rightSide}>
          {large && showLargeLogo && (
            <Link
              className={`${css.text} ${hiddenInMobile}`}
              to={`mailto:${companyInfo.email.replace(/ /g, "")}`}
            >
              {companyInfo.email}
            </Link>
          )}
          <Link
            className={`${css.text} ${hiddenInMobile}`}
            to={`tel:${companyInfo.telephoneNumber.replace(/ /g, "")}`}
          >
            {companyInfo.telephoneNumber}
          </Link>
          {companyInfo.bookingSystem && (
            <Link
              className={hiddenInMobile}
              button
              to={companyInfo.bookingSystem}
            >
              Check availability
            </Link>
          )}
          {companyInfo?.fbUrl && (
            <Link
              className={`${css.social} ${css.fb}`}
              to={companyInfo.fbUrl}
            >
              <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M140.6 27.6C128.1 30.6 117.1 39 111.7 49.6C107 58.9 105.9 64.6 105.3 82.7L104.7 99.9L90.1 100.2L75.5 100.5V120.5V140.5L90.3 140.8L105 141.1V183C105 207.8 105.4 225.9 106 227.4L107 230H126.9C145.7 230 147 229.9 148 228.1C148.6 226.8 149 211 149 183.6V141H165.3C176.6 141 182 140.6 182.8 139.8C183.6 139 184 133.2 184 120.4C184 105.1 183.7 101.9 182.4 100.6C181.1 99.3 178.1 99 164.9 99H149V85.1C149 73.2 149.3 70.9 150.8 69C153.1 66.2 159 65.1 172.6 65.1C179 65 183 64.6 183.8 63.8C185.5 62.1 185.5 28.9 183.8 27.2C182 25.4 148.3 25.7 140.6 27.6Z" fill="#3B5998"/>
              </svg>
            </Link>
          )}
        </div>
      </div>
      <div className={css.pageIntro} style={(video || image) && !showLargeLogo ? { top: '50%' } : undefined}>
        {(image || video) && showLargeLogo && (
          <div className={css.largeLogoWrapper}>
            <Image fixed={companyInfo.logoLargeWhite.fixed} />
          </div>
        )}
        {((!video && !image) || !showLargeLogo) && (
          <>
            <h1>{heading}</h1>
            {subheading && <p className={xlp}>{subheading}</p>}
          </>
        )}
      </div>
      {large && showLargeLogo && (
        <div
          className={css.scrollArrow}
          onClick={() => {
            scrollTo("page-content")
          }}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.5694 30.9266L31.6249 28.9821L23.3751 37.2318L23.3751 1.375L20.6251 1.375L20.6251 37.2316L12.3756 28.9821L10.4311 30.9266L22.0002 42.4959L33.5694 30.9266Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default Hero
